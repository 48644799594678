<template>
	<div class="main">
		<!-- 我的收藏 -->
		<div class="nav-lf">
			<MusicType :width="100" :type="type" :style="{ paddingLeft: num + 'px' }" :tabsList="tabsList"
				:dropdownList="dropdownList"></MusicType>
		</div>
		<div class="main-box">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import MusicType from "@/components/content/MusicType.vue";
	export default {
		props: {},
		computed: {},
		components: {
			MusicType
		},
		data() {
			return {
				num: '',
				type: false,
				dropdownList: [],
				// tabs数据
				tabsList: [],
				// tabsList: [{
				// 		name: this.$t('finishedmusic'),
				// 		url: '/personalCenter/myCollection/collectMusic'
				// 	},
				// 	{
				// 		name: `DEMO(${this.$t('overseas')})`,
				// 		url: '/personalCenter/myCollection/collectsea'
				// 	},
				// 	{
				// 		name: `DEMO(${this.$t('hinterland')})`,
				// 		url: '/personalCenter/myCollection/collectinland'
				// 	},
				// 	{
				// 		name: this.$t('recommend.WorkCollaboration'),
				// 		url: '/personalCenter/myCollection/collectarea'
				// 	},
				// ],
			}
		},
		created() {
			if(this.$store.state.identity == 2){
				let arr = [{
						name: this.$t('finishedmusic'),
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name: `DEMO(${this.$t('overseas')})`,
						url: '/personalCenter/myCollection/collectsea'
					},
					{
						name: `DEMO(${this.$t('hinterland')})`,
						url: '/personalCenter/myCollection/collectinland'
					}
				]
				this.tabsList = arr
			}else {
				let arr = [{
						name: this.$t('finishedmusic'),
						url: '/personalCenter/myCollection/collectMusic'
					},
					{
						name: `DEMO(${this.$t('overseas')})`,
						url: '/personalCenter/myCollection/collectsea'
					},
					{
						name: `DEMO(${this.$t('hinterland')})`,
						url: '/personalCenter/myCollection/collectinland'
					},
					{
						name: this.$t('recommend.WorkCollaboration'),
						url: '/personalCenter/myCollection/collectarea'
					},
				]
				this.tabsList = arr
			}
		},
		methods: {

		}
	}
</script>

<style lang="less" scoped>
	.main {

		// color: palevioletred;
		::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @lineColor;
		}

		.nav-lf {
			margin-left: 28px;
		}

		.main-box {
			margin-left: 30px;
		}
	}
</style>
